// --- PRIORITY MODULES ---

// Polyfills
import './mp-app/polyfills';
// Lazysizes
import './mp-app/modules/lazysizes.js';
// Global
import 'jquery';

// --- END-PRIORITY MODULES ---

// --- Deferred modules ---

// jQuery is top-priority (most modules depend on it to be ready)
import('jquery').then(async (_) => {

	/**
	 * Initialize the config first
	 * NOTE: very important that we await the config load because there are multiple places in the modules that require the config in module load
	 */
	await import('./mp-app/config/_init-config.js');

	/**
	 * Import all priority modules (websocket, etc)
	 */
	import('./mp-app/config/_init-priority.js');

	/**
	 * Import the rest
	 * of the modules
	 */
	import('./mp-app/config/_init-modules.js');

	// Remove the loading animation separately, so errors cannot prevent it from being removed
	import('./mp-app/theme/remove-loading.js');

	// TODO: add await + Promise.all for the above + run mpro.init() after all modules are loaded (remote it from _init-modules)
});

// --- END-Deferred modules ---

export default mpro;