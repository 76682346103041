// https://github.com/Financial-Times/polyfill-library/tree/master/polyfills/MediaQueryList/prototype/addEventListener

// Safari < 14
if ('matchMedia' in self && !('addEventListener' in (self.matchMedia('all')))) {

	/** @this {MediaQueryList} */
	const addEventListener = function addEventListener(type, listener) {
		if (type === 'change') {
			this.removeListener(listener);
			this.addListener(listener);
		}

		if (arguments[2] && arguments[2].once) {
			const _this = this;
			const remover = function() {
				_this.removeListener(remover);
				_this.removeListener(listener);
			};
			this.addListener(remover);
		}
	};

	/** @this {MediaQueryList} */
	const removeEventListener = function removeEventListener(type, listener) {
		if (type === 'change') {
			this.removeListener(listener);
		}
	};

	const onchangeDescriptor = {
		enumerable: true,
		configurable: true,
		get() {
			return this._onchangeHandler || null;
		},
		set(listener) {
			const _this = this;
			if (!_this._onchangeListener) {
				_this._onchangeListener = function() {
					if (typeof _this._onchangeHandler !== 'function') {
						return;
					}

					_this._onchangeHandler.call(_this, arguments[0]);
				};
				_this.addEventListener('change', _this._onchangeListener);
			}

			_this._onchangeHandler = listener;
		},
	};

	if ('MediaQueryList' in self) { /* Most browsers expose "MediaQueryList" globally */
		const _addListener = self.MediaQueryList.prototype.addListener;
		const _removeListener = self.MediaQueryList.prototype.removeListener;

		self.MediaQueryList.prototype.addListener = function addListener(listener) {
			let handler = listener;
			if (handler.handleEvent) {
				handler = handler.handleEvent;
			}
			_addListener.call(this, handler);
		};

		self.MediaQueryList.prototype.removeListener = function removeListener(listener) {
			let handler = listener;
			if (handler.handleEvent) {
				handler = handler.handleEvent;
			}
			_removeListener.call(this, handler);
		};

		self.MediaQueryList.prototype.addEventListener = addEventListener;
		self.MediaQueryList.prototype.removeEventListener = removeEventListener;

		self.Object.defineProperty(self.MediaQueryList.prototype, 'onchange', onchangeDescriptor);
	}
	/* Safari does not expose "MediaQueryList" globally */
	else {
		// @ts-ignore
		const _matchMedia = self.matchMedia;

		// @ts-ignore
		self.matchMedia = function matchMedia(media) {
			const _mql = _matchMedia(media);

			const _addListener = _mql.addListener;
			const _removeListener = _mql.removeListener;

			_mql.addListener = function addListener(listener) {
				let handler = listener;
				if (handler.handleEvent) {
					handler = handler.handleEvent;
				}
				_addListener.call(this, handler);
			};

			_mql.removeListener = function removeListener(listener) {
				let handler = listener;
				if (handler.handleEvent) {
					handler = handler.handleEvent;
				}
				_removeListener.call(this, handler);
			};

			_mql.addEventListener = addEventListener;
			_mql.removeEventListener = removeEventListener;

			self.Object.defineProperty(_mql, 'onchange', onchangeDescriptor);

			return _mql;
		};
	}
}